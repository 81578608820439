"use client";

import React, {
  useCallback,
  useRef,
  useEffect,
  MouseEventHandler,
} from "react";
import { useRouter } from "next/navigation";
import Image from "next/image";
import closeIcon from "@book-the-play/public/img/svg/modelCloseBtn.svg";

const Modal = ({ children }: { children: React.ReactNode }) => {
  const overlay = useRef(null);
  const wrapper = useRef(null);
  const router = useRouter();

  const onDismiss = useCallback(() => {
    router.back();
  }, [router]);

  const onClick: MouseEventHandler = useCallback(
    (e) => {
      if (e.target === overlay.current || e.target === wrapper.current) {
        onDismiss();
      }
    },
    [onDismiss, overlay, wrapper],
  );

  const onKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === "Escape") onDismiss();
    },
    [onDismiss],
  );

  useEffect(() => {
    document.addEventListener("keydown", onKeyDown);

    return () => document.removeEventListener("keydown", onKeyDown);
  }, [onKeyDown]);

  return (
    <div
      ref={overlay}
      className="modal-overlay appear-animation"
      data-appear-animation="fadeIn"
      data-appear-animation-delay={50}
      onClick={onClick}
    >
      <div ref={wrapper} className="modal-absolute">
        {children}
        <Image
          src={closeIcon}
          alt="close-icon"
          className="wb-absolute wb-top-2 wb-right-3 wb-mb-5 wb-cursor-pointer"
          onClick={onDismiss}
        />
      </div>
    </div>
  );
};

export default Modal;
