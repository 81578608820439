import { ClientLink } from "@book-the-play/components/ClientLink";
import { headerMenu } from "@book-the-play/utils/constant";

import "./style/mobileMenuList.scss";

interface Props {
  isMobileMenuVisible: boolean;
}

export const MobileMenuList = ({ isMobileMenuVisible }: Props) => {
  return (
    <div
      className={`header-tab-popup ${!isMobileMenuVisible ? "wb-hidden" : ""}`}
      id="mobile-header"
    >
      <div className="btn-wrapper">
        <ul className="tab-list" id="mobile-nav">
          {headerMenu.map(({ id, path, title }) => (
            <li key={id}>
              <ClientLink href={path}>{title}</ClientLink>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
