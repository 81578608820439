"use client";
import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";
import Image from "next/image";
import { Typography } from "@libs/src/components/Typography";
import {
  frequentlyAskQuestionList,
  iconHeight,
  iconWidth,
} from "@book-the-play/utils/constant";
import { CONTAINER_VARIANTS, PARAGRAPH_VARIANTS } from "@libs/utils/animation";
import angleIcon from "@book-the-play/public/img/svg/angle-down.svg";

export const FrequentlyAskQuestionAccordion = () => {
  const [openAccordionId, setOpenAccordionId] = useState(0);

  const isOpenAccordion = (accordionId: number) =>
    openAccordionId === accordionId;

  const handleClick = (id: number) =>
    setOpenAccordionId((prev) => (prev === id ? 0 : id));

  return (
    <div className="wb-my-10 wb-flex wb-flex-col wb-gap-5">
      {frequentlyAskQuestionList.map(({ id, title, description }) => (
        <motion.div
          key={id}
          variants={CONTAINER_VARIANTS}
          initial="initial"
          animate="final"
          transition={{ duration: 0.5, ease: [0.04, 0.62, 0.23, 0.98] }}
        >
          <button
            className="wb-text-xl wb-font-semibold wb-py-6 wb-px-8 wb-cursor-pointer wb-flex wb-items-center wb-justify-between wb-bg-gray-100 wb-w-full wb-text-start"
            onClick={() => handleClick(id)}
          >
            <Typography
              tag="span"
              className="wb-text-dark wb-text-base wb-tracking-wider"
            >
              {title}
            </Typography>
            <Image
              src={angleIcon}
              alt="angle-down"
              width={iconWidth}
              height={iconHeight}
              className={`wb-w-[40px] wb-h-[40px] ${
                isOpenAccordion(id) ? "wb-rotate-180 wb-transition-all" : ""
              }`}
            />
          </button>
          <AnimatePresence initial={false}>
            {isOpenAccordion(id) && (
              <motion.p
                className="wb-px-8 wb-text-sm"
                variants={PARAGRAPH_VARIANTS}
                initial="initial"
                animate="final"
                exit="initial"
                transition={{
                  duration: 0.3,
                }}
              >
                <div className="wb-py-8">{description}</div>
              </motion.p>
            )}
          </AnimatePresence>
        </motion.div>
      ))}
    </div>
  );
};
