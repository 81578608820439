"use client";

import Image from "next/image";
import { Swiper, SwiperSlide } from "swiper/react";
import { Mousewheel, Navigation } from "swiper/modules";
import { Transition } from "@libs/src/components/Transition";
import { FADE_TO_SHOW_ANIMATION } from "@libs/utils/animation";
import {
  bookThePlayImages,
  extraLargeHeight,
  imageQuality,
  mediumWidth,
} from "@book-the-play/utils/constant";
import "swiper/css";
import "swiper/css/navigation";

export const OverviewSwiper = () => {
  return (
    <div className="wb-flex wb-justify-center wb-items-center wb-ml-3">
      <div className="custom-mobile-frame">
        <Transition
          className="wb-w-[92%] wb-relative wb-z-10 wb-mb-4 wb-pt-[57px] wb-pl-1"
          transition={{
            duration: 0.5,
            delay: 0.8,
          }}
          variants={FADE_TO_SHOW_ANIMATION}
        >
          <Swiper
            navigation
            modules={[Mousewheel, Navigation]}
            direction="horizontal"
            className="wb-w-[92%]"
          >
            {bookThePlayImages.map(({ id, path, alternateName }) => (
              <SwiperSlide key={id}>
                <div>
                  <Image
                    alt={alternateName}
                    className="btp-image"
                    src={path}
                    width={mediumWidth}
                    height={extraLargeHeight}
                    quality={imageQuality}
                  />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </Transition>
      </div>
    </div>
  );
};
