import { endpoint } from "./apiKeys";

export interface FormProps {
  name: string;
  email: string;
  mobile: string;
  message: string;
}

export const useSendMessage = () => {
  const postData = async (data: FormProps) => {
    const res = await fetch(endpoint.becomeAPartnerApi, {
      method: "POST",
      body: JSON.stringify({ formData: data }),
      headers: {
        "Content-Type": "application/json",
      },
    });

    return res;
  };

  return { postData };
};
