"use client";

import { useState } from "react";
import Image from "next/image";
import Link from "next/link";
import { usePathname } from "next/navigation";
import ClickAwayListener from "react-click-away-listener";
import { ClientLink } from "./ClientLink";
import { MobileMenuList } from "./MobileMenuList";
import { routes } from "@book-the-play/utils/route";
import {
  footerSocialLinks,
  headerMenu,
  iconHeight,
  iconWidth,
} from "@book-the-play/utils/constant";
import siteLogo from "@book-the-play/public/logo/color.svg";
import mobileMenuBar from "@book-the-play/public/img/svg/menuBar.svg";
import "./style/header.scss";

const logoWidth = 120;
const logoHeight = 32;

export const Header = () => {
  const pathname = usePathname();

  const [isMobileMenu, setIsMobileMenu] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenu(!isMobileMenu);
  };

  return (
    <header className="wb-bg-light wb-border-b-[1px] wb-fixed wb-w-full wb-top-0 wb-z-50">
      <div className="header-section main-container">
        <Link href={routes.home}>
          <Image
            src={siteLogo}
            width={logoWidth}
            height={logoHeight}
            alt="book the play logo"
            priority
          />
        </Link>
        {pathname === routes.home && (
          <div className="lg:wb-flex wb-flex-row wb-gap-5 wb-text-base wb-font-medium wb-hidden wb-items-center">
            {headerMenu.map(({ id, path, title }) => (
              <ClientLink href={path} key={id}>
                {title}
              </ClientLink>
            ))}

            <div className="wb-hidden wb-flex-row wb-gap-3 wb-pl-3 wb-items-center md:wb-flex">
              {footerSocialLinks
                .slice(0, 3)
                .map(({ className, imagePath, imgAlt, path, title }) => (
                  <Link
                    href={path}
                    className="header-social-links"
                    key={title}
                    target="_blank"
                  >
                    <Image
                      src={imagePath}
                      alt={imgAlt}
                      width={iconWidth}
                      height={iconHeight}
                      className={className}
                    />
                  </Link>
                ))}
            </div>
          </div>
        )}
        <ClickAwayListener onClickAway={() => setIsMobileMenu(false)}>
          <div className="flex-center wb-bg-primary wb-text-white wb-h-10 wb-w-12 lg:wb-hidden">
            <button
              onClick={toggleMobileMenu}
              className="wb-h-full wb-w-full flex-center"
            >
              <Image
                src={mobileMenuBar}
                alt="mobileMenuBar"
                width={iconWidth}
                height={iconHeight}
              />
            </button>
            <MobileMenuList isMobileMenuVisible={isMobileMenu} />
          </div>
        </ClickAwayListener>
      </div>
    </header>
  );
};
